const pages = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    redirect: '/chat'
  },
  {
    path: '/chat',
    name: 'chat',
    component:() => import('@/pages/chat/index.vue')
  },
  {
    path: '/message',
    name: 'message',
    component:() => import('@/pages/message/index.vue')
  },
  {
    path: '/placeOrder',
    name: 'placeOrder',
    component: () => import('@/pages/placeOrder/index.vue')
  },
  {
    path: '/propertyManager',
    name: 'propertyManager',
    component: () => import('@/pages/propertyManager/index.vue')
  }
]


export default pages;