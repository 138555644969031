<template>
  <router-view></router-view>
</template>

<script>
export default {

}
</script>

<style lang="scss">
body {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: 0;
  padding: 0;
}
:root {
  --boder-color: #eee;
}
</style>