import Vue from "vue";
import App from '@/App';
import router from '@/common/router';
const init = function () {
  new Vue({
    el: '#app',
    router,
    render: (e) => e(App)
  });
};
if (location.pathname == '/message') {
  init();
} else {
  import('element-ui/lib/theme-chalk/index.css');
  import('element-ui').then(ElementUI => {
    Vue.use(ElementUI);
    init();
  });
}
