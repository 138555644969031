import routes from './pages';
import VueRouter from 'vue-router';
import Vue from 'vue';
Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  routes
})
router.beforeEach((to, from, next) => {
  next();
})
export default router;